import { Component, Vue } from 'vue-property-decorator';
import { ICellRendererParams } from 'ag-grid-community';
import { EventLocaleType } from '@/types/api/events/event-locale.type';
import { SpotLocaleType } from '@/types/api/spot/spot-locale.type';
import { ActivityLocaleType } from '@/types/api/info/activity-locale.type';
import { Getter } from 'vuex-class';
import { LanguageType } from '@/types/api/info/language.type';
import isFunction from 'lodash/isFunction';

type LocalesCellRendererParams = ICellRendererParams & {
  locales: (params: ICellRendererParams) => EventLocaleType[] | SpotLocaleType[] | ActivityLocaleType[];
};

@Component
export default class LocalesCellRenderer extends Vue {
  params!: LocalesCellRendererParams;

  @Getter('getLanguages', { namespace: 'info' })
  languages!: LanguageType[];

  get locales() {
    let locales: any = this.params.locales || [];
    if (isFunction(locales)) {
      locales = locales(this.params);
    }

    return locales;
  }

  getLanguageTitle(langId: number) {
    const lang = this.languages.find(item => item.id === langId);
    return lang ? lang.code : '';
  }
}
