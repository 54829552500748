import { i18n } from '@/i18n';

export const OFFER_STATUS_NEW = 1;
export const OFFER_STATUS_USED = 2;
export const OFFER_STATUS_EXPIRED = 3;
export const OFFER_STATUS_CANCELED = 4;

export const getOfferStatusLabel = (status: number) => {
    switch (status) {
        case OFFER_STATUS_NEW: return i18n.t('offerStatus.new');
        case OFFER_STATUS_USED: return i18n.t('offerStatus.used');
        case OFFER_STATUS_EXPIRED: return i18n.t('offerStatus.expired');
        case OFFER_STATUS_CANCELED: return i18n.t('offerStatus.canceled');
        default: return '';
    }
}

export const getOfferStatusList = () => [
    { value: OFFER_STATUS_NEW, text: getOfferStatusLabel(OFFER_STATUS_NEW) },
    { value: OFFER_STATUS_USED, text: getOfferStatusLabel(OFFER_STATUS_USED) },
    { value: OFFER_STATUS_EXPIRED, text: getOfferStatusLabel(OFFER_STATUS_EXPIRED) },
    { value: OFFER_STATUS_CANCELED, text: getOfferStatusLabel(OFFER_STATUS_CANCELED) },
]
