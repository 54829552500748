import { render, staticRenderFns } from "./localesCellRenderer.vue?vue&type=template&id=605d3c56&scoped=true&"
import script from "./localesCellRenderer.ts?vue&type=script&lang=ts&"
export * from "./localesCellRenderer.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "605d3c56",
  null
  
)

export default component.exports