import { Component, Vue } from 'vue-property-decorator';
import { ICellRendererParams } from 'ag-grid-community';
import isFunction from 'lodash/isFunction';
import BRadioInputValidation from '@/components/ui/inputs/b-radio-input-validation/bRadioInputValidation.vue';
import { getOfferStatusList, OFFER_STATUS_NEW } from '@/constants/offer-status.constants';

type OfferStatusCellRendererParams = ICellRendererParams & {
  status: ((params: ICellRendererParams) => number) | number;
  onChange: (value, params: ICellRendererParams) => any;
};

@Component({
  components: {
    BRadioInputValidation
  }
})
export default class OfferStatusCellRenderer extends Vue {
  params!: OfferStatusCellRendererParams;
  innerValue: number = OFFER_STATUS_NEW;

  created() {
    this.innerValue = this.status;
  }

  get status() {
    if (isFunction(this.params.status)) {
      return this.params.status(this.params);
    }
    return this.params.status;
  }

  onClick(value) {
    if (isFunction(this.params.onChange)) {
      this.$modal.show('dialog', {
        title: '',
        text: this.$t('offer.changeStatusQuestion'),
        buttons: [
          {
            title: 'Ok',
            handler: () => {
              this.$modal.hide('dialog');
              this.params.onChange(value, this.params);
              this.innerValue = value;
            }
          },
          {
            title: this.$t('common.cancel'),
            handler: () => {
              this.$modal.hide('dialog');
              this.innerValue = this.status;
            }
          }
        ]
      });
    }
  }

  get offerStatusOptions() {
    return getOfferStatusList();
  }
}
