<template>
  <div>
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title"></h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="cancel">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <div class="gmap">
          <GmapMap
            ref="map"
            :center="placePosition"
            :zoom="zoom"
            style="width: 100%; height: 100%"
          >
            <GmapInfoWindow
              v-if="address"
              :options="infoOptions"
              :position="placePosition"
              :opened="isInfoOpen"
              @closeclick="infoWinOpen = false"
            ></GmapInfoWindow>

            <GmapMarker
              label=""
              :position="placePosition"
              :clickable="true"
              @click="isInfoOpen = !isInfoOpen"
            />
          </GmapMap>
        </div>
      </div>

      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          data-dismiss="modal"
          @click="cancel"
        >
          {{ $t('common.cancel') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      lat: {
        required: true,
        type: Number
      },
      lng: {
        required: true,
        type: Number
      },
      address: {
        required: false,
        type: String
      },
    },
    data: function () {
      return {
        zoom: 13,
        isInfoOpen: true,
        infoOptions: {
          content: this.address || '',
          pixelOffset: {
            width: 0,
            height: -35
          }
        }
      };
    },
    methods: {
      cancel: function () {
        this.$emit('close');
      },
    },
    computed: {
      placePosition: function() {
        return {
          lat: this.lat,
          lng: this.lng,
        };
      }
    }
  }
</script>

<style lang="scss" scoped>
  .gmap {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px;
    width: 100%
  }
</style>
