import { Component, Vue } from 'vue-property-decorator';
import { ICellRendererParams } from 'ag-grid-community';
import isFunction from 'lodash/isFunction';
import { SpotActivityType } from '@/types/api/activity/spot-activity.type';
import { EventActivityType } from '@/types/api/activity/event-activity.type';
import ShowActivitiesWidget from '@/components/ui/show-activities-widget/showActivitiesWidget.vue';

type ActivitiesCellRendererParams = ICellRendererParams & {
  activities: (params: ICellRendererParams) => SpotActivityType[] | EventActivityType[];
};

@Component({
  components: {
    ShowActivitiesWidget
  }
})
export default class ActivitiesCellRenderer extends Vue {
  params!: ActivitiesCellRendererParams;

  get activities() {
    if (isFunction(this.params.activities)) {
      return this.params.activities(this.params);
    }
    return [];
  }
}
