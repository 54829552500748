import { Component, Prop, Vue } from 'vue-property-decorator';
import { SpotActivityType } from '@/types/api/activity/spot-activity.type';
import { EventActivityType } from '@/types/api/activity/event-activity.type';

@Component
export default class ShowActivitiesWidget extends Vue {
  @Prop({
    required: true
  })
  activity!: EventActivityType | SpotActivityType;
}
