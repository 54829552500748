import { Component, Vue } from 'vue-property-decorator';
import { ICellRendererParams } from 'ag-grid-community';
import isFunction from 'lodash/isFunction';

type ContentCellRendererParams = ICellRendererParams & {
  content: ((params: ICellRendererParams) => any) | any;
  clickCallback?: (params: ICellRendererParams) => any | null;
  clickable: ((params: ICellRendererParams) => boolean) | boolean;
};

@Component
export default class ContentCellRenderer extends Vue {
  params!: ContentCellRendererParams;

  get content() {
    if (isFunction(this.params.content)) {
      return this.params.content(this.params);
    }
    return this.params.content;
  }

  get clickable() {
    if (isFunction(this.params.clickable)) {
      return this.params.clickable(this.params);
    }
    return this.params.clickable || false;
  }

  onClick() {
    if (isFunction(this.params.clickCallback) && this.clickable) {
      this.params.clickCallback!(this.params);
    }
  }
}
