import { Component, Vue } from 'vue-property-decorator';
import { ICellRendererParams } from 'ag-grid-community';
import isFunction from 'lodash/isFunction';

type DialogCellRendererParams = ICellRendererParams & {
  text: (params: ICellRendererParams) => string | string;
  title: (params: ICellRendererParams) => string | string;
  btnContent: string;
};

@Component
export default class DialogCellRenderer extends Vue {
  params!: DialogCellRendererParams;

  get text(): string {
    if (isFunction(this.params.text)) {
      return this.params.text(this.params);
    }
    return this.params.text;
  }

  get title(): string {
    if (isFunction(this.params.title)) {
      return this.params.title(this.params);
    }
    return this.params.title || '';
  }

  get btnContent() {
    return this.params.btnContent || '<i class="fe-info"></i>';
  }

  onClick() {
    this.$modal.show('dialog', {
      title: this.title,
      text: this.text,
      buttons: [
        {
          title: 'OK',
          handler: () => {
            this.$modal.hide('dialog');
          }
        }
      ]
    });
  }
}
