import { UserType } from '@/types/api/user/user.type';
import { LanguageType } from '@/types/api/info/language.type';

export const isMobile = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(navigator.userAgent);
};

export const MODAL_CONFIG = {
  adaptive: true,
  height: isMobile() ? '100%' : 'auto',
  ...(isMobile() ? { width: '100%' } : {}),
};

export const getUserName = (user: UserType) => {
  return user.firstName || user.lastName ? `${user.firstName || ''} ${user.lastName || ''}` : user.id;
}

export const getSocialNetworkLink = (user: UserType) => {
  if (user?.socialProfileId) {
    const createLink = (link: string): string => {
      return `<a href="${link}">${link}</a>`
    }

    switch (user.socialName) {
      case 'facebook':
        return createLink('https://www.facebook.com/' + user.socialProfileId);
      case 'linkedin':
        return createLink('https://www.linkedin.com/in/' + user.socialProfileId);
      default:
        return '';
    }
  }

  return '';
}

export const getLanguageByCode = (code: string, languages: LanguageType[]) => languages.find(item => item.code === code);
