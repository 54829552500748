import { Component, Vue } from 'vue-property-decorator';
import { ICellRendererParams } from 'ag-grid-community';
import isFunction from 'lodash/isFunction';

interface IBtnOptions {
  disabled: (params: ICellRendererParams) => boolean | boolean;
  show: (params: ICellRendererParams) => boolean | boolean;
  actionCallback: (params: ICellRendererParams) => any | null;
  btnContent: string;
  type: string;
}

type ActionsCellRendererParams = ICellRendererParams & {
  buttons: IBtnOptions[]
};

@Component
export default class ActionsCellRenderer extends Vue {
  params!: ActionsCellRendererParams;

  onClick(btn: IBtnOptions) {
    if (isFunction(btn.actionCallback)) {
      btn.actionCallback(this.params);
    }
  }

  btnContent(btn: IBtnOptions) {
    return btn.btnContent || '<i class="fe-edit"></i>';
  }

  isDisabled(btn: IBtnOptions): boolean {
    if (isFunction(btn.disabled)) {
      return btn.disabled(this.params);
    }
    return !!btn.disabled;
  }

  isShow(btn: IBtnOptions): boolean {
    if (isFunction(btn.show)) {
      return btn.show(this.params);
    }
    return btn.show === undefined ? true : !!btn.show;
  }
}
