import { Component, Vue } from 'vue-property-decorator';
import { ICellRendererParams } from 'ag-grid-community';
import isFunction from 'lodash/isFunction';

interface AgCellBadgeItem {
  label: string,
  active: boolean,
}

type BadgesCellRendererParams = ICellRendererParams & {
  items: ((params: ICellRendererParams) => AgCellBadgeItem[]) | AgCellBadgeItem[];
  displayColumn?: boolean;
};

@Component
export default class BadgesCellRenderer extends Vue {
  params!: BadgesCellRendererParams;

  get items(): AgCellBadgeItem[] {
    let items: any = this.params.items || [];
    if (isFunction(items)) {
      items = items(this.params);
    }

    return items;
  }

  get displayColumn(): boolean {
    const { displayColumn = true } = this.params;
    return displayColumn;
  }
}
