import { Component, Vue } from 'vue-property-decorator';
import { ICellRendererParams } from 'ag-grid-community';
import isFunction from 'lodash/isFunction';
import { PointType } from '@/types/api/point.type';
import ShowLocationGoogleMap from '@/components/ui/modals/showLocationGoogleMap.vue';

type NameAddressCellRendererParams = ICellRendererParams & {
  imageUrl: ((params: ICellRendererParams) => string) | string;
  geopoint: ((params: ICellRendererParams) => PointType) | PointType;
  text: ((params: ICellRendererParams) => string) | string;
  url?: ((params: ICellRendererParams) => string) | string;
  address?: ((params: ICellRendererParams) => string) | string;
};

@Component
export default class NameAddressCellRenderer extends Vue {
  params!: NameAddressCellRendererParams;

  // mounted() {
  //   if (this.geopoint && this.geopoint.coordinates.length) {
  //     searchByCoordinates(this.geopoint.coordinates[1], this.geopoint.coordinates[0])
  //       .then(response => {
  //         if (response[0]) {
  //           this.address = response[0].label;
  //         }
  //       });
  //   }
  // }

  get text() {
    if (isFunction(this.params.text)) {
      return this.params.text(this.params);
    }
    return this.params.text;
  }

  get url() {
    if (isFunction(this.params.url)) {
      return this.params.url(this.params);
    }
    return this.params.url;
  }

  get address() {
    if (isFunction(this.params.address)) {
      return this.params.address(this.params);
    }
    return this.params.address;
  }

  get imageUrl(): string {
    if (isFunction(this.params.imageUrl)) {
      return this.params.imageUrl(this.params);
    }
    return this.params.imageUrl;
  }

  get geopoint(): PointType {
    if (isFunction(this.params.geopoint)) {
      return this.params.geopoint(this.params);
    }
    return this.params.geopoint;
  }

  showLocation() {
    this.$modal.show(ShowLocationGoogleMap, {
      lat: this.geopoint.coordinates[1],
      lng: this.geopoint.coordinates[0],
    }, {
      adaptive: true,
      height: 'auto',
    });
  }
}
