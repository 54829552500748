import merge from 'lodash/merge';

export const IN_RANGE_AG_FILTER_OPTIONS = 'inRange';
export const EQUALS_AG_FILTER_OPTIONS = 'equals';
export const CONTAINS_AG_FILTER_OPTIONS = 'contains';

export type AgType = typeof IN_RANGE_AG_FILTER_OPTIONS
  | typeof EQUALS_AG_FILTER_OPTIONS
  | typeof CONTAINS_AG_FILTER_OPTIONS

interface agConditionFilterParams {
  type: AgType;
  filterType?: string;
  filter?: string | number;
  filterTo?: string | number | null;
  dateFrom?: string,
  dateTo?: string,
}

const TYPES_MAP = {
  [EQUALS_AG_FILTER_OPTIONS]: '_eq',
  [IN_RANGE_AG_FILTER_OPTIONS]: { from: '_gte', to: '_lt' },
  [CONTAINS_AG_FILTER_OPTIONS]: '_ilike',
};

export class AgGridFilterBuilder {
  /**
   * Get prepared graphql filter query
   * @param filters
   */
  public static getGqlWhereQuery(filters: any) {
    const where: any = {};
    for (const [field, params] of Object.entries(filters)) {
      const condition = this.getCondition(params as agConditionFilterParams);
      merge(where, this.parseField(field, condition));
    }

    return where;
  }

  /**
   * Convert ag-grid filter format to graphql format
   * @param params
   */
  private static getCondition(params: agConditionFilterParams) {
    const from = params.filterType === 'date' ? params.dateFrom : params.filter;
    const to = params.filterType === 'date' ? params.dateTo : params.filterTo;

    switch (params.type) {
      case EQUALS_AG_FILTER_OPTIONS:
        return { [this.getType(params.type)]: params.filter };
      case IN_RANGE_AG_FILTER_OPTIONS:
        return {
          [this.getType(params.type).from]: from,
          [this.getType(params.type).to]: to,
        };
      case CONTAINS_AG_FILTER_OPTIONS:
        return  { [this.getType(params.type)]: `%${params.filter}%` };
    }
  }

  /**
   * Parse string to object ('user.profile.name' => {user: {profile: {name: 'User Name'}}})
   * @param field
   * @param value
   */
  private static parseField(field: string, value: any) {
    return field.split('.')
      .reverse()
      .reduce((res: any, cur, index) => ({[cur]: index === 0 ? value : res}), {});
  }

  private static getType(type: AgType): any {
    return TYPES_MAP[type];
  }
}
