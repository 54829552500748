import { Component, Vue, Watch } from 'vue-property-decorator';
import { IDoesFilterPassParams, IFilter, IFilterParams } from 'ag-grid-community';
import { EQUALS_AG_FILTER_OPTIONS } from '@/helpers/ag-grid/ag-grid-filter-builder';

type AgSelectFilterParams = IFilterParams & {
  values: { id: number, name: string },
  applyButton?: true,
  resetButton?: true,
  clearButton?: true,
}

@Component
export default class agSelectColumnFilter extends Vue implements IFilter {
  selected = '';
  params!: AgSelectFilterParams;

  @Watch('selected')
  onSelectValueChanged(value: number, oldVal: number) {
    if (!this.params.applyButton && value !== oldVal) {
      this.params.filterChangedCallback();
    }
  }

  isFilterActive() {
    return this.selected !== null && this.selected !== undefined && this.selected !== '';
  }

  doesFilterPass(params: IDoesFilterPassParams) {
    return !!this.selected;
  }

  getModel() {
    return {
      type: EQUALS_AG_FILTER_OPTIONS,
      filterType: 'select',
      filter: this.selected
    };
  }

  setModel(model: any) {
    if (model) {
      this.selected = model.filter;
    }
  }

  afterGuiAttached() {
    (this.$refs.select as HTMLInputElement).focus();
  }

  apply() {
    this.params.filterChangedCallback();
  }

  cancel() {
    this.selected = '';
  }

  reset() {
    this.cancel();
    this.params.api.destroyFilter(this.params.colDef.field!);
  }
}
