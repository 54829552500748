import { Component, Vue } from 'vue-property-decorator';
import { ICellRendererParams } from 'ag-grid-community';
import isFunction from 'lodash/isFunction';

interface LinkUserType {
  userId: number;
  firstName?: string;
  lastName?: string;
  email: string;
}

type UserCellRendererParams = ICellRendererParams & {
  user: (params: ICellRendererParams) => LinkUserType | LinkUserType;
};

@Component
export default class UserCellRenderer extends Vue {
  params!: UserCellRendererParams;

  get user() {
    if (isFunction(this.params.user)) {
      return this.params.user(this.params);
    }
    return this.params.user;
  }

  get userName() {
    return this.user.firstName || this.user.lastName
      ? `${this.user.lastName || ''} ${this.user.firstName || ''} (${this.user.email})`
      : this.user.email;
  }
}
