import { Component, Vue } from 'vue-property-decorator';
import { ICellRendererParams } from 'ag-grid-community';
import isFunction from 'lodash/isFunction';
// @ts-ignore
import userImg from '@/assets/images/user.png';

type UserInfoCellRendererParams = ICellRendererParams & {
  name: ((params: ICellRendererParams) => string) | string;
  url?: ((params: ICellRendererParams) => string) | string;
  phone?: ((params: ICellRendererParams) => string) | string;
  email?: ((params: ICellRendererParams) => string) | string;
  avatar?: ((params: ICellRendererParams) => string) | string;
};

@Component
export default class UserInfoCellRenderer extends Vue {
  params!: UserInfoCellRendererParams;

  get name() {
    if (isFunction(this.params.name)) {
      return this.params.name(this.params);
    }
    return this.params.name;
  }

  get url() {
    if (isFunction(this.params.url)) {
      return this.params.url(this.params);
    }
    return this.params.url;
  }

  get phone() {
    if (isFunction(this.params.phone)) {
      return this.params.phone(this.params);
    }
    return this.params.phone;
  }

  get email() {
    if (isFunction(this.params.email)) {
      return this.params.email(this.params);
    }
    return this.params.email;
  }

  get avatar() {
    if (isFunction(this.params.avatar)) {
      return this.params.avatar(this.params) || userImg;
    }
    return this.params.avatar || userImg;
  }
}
