import {
  ColumnApi,
  GridApi,
  GridOptions,
  GridReadyEvent,
} from 'ag-grid-community';
import { GridDatasource, GridDatasourceCallback } from './ag-grid-datasource';
import isFunction from 'lodash/isFunction';
import { i18n } from '@/i18n';
import throttle from 'lodash/throttle';
import agSelectColumnFilter from '@/components/ui/ag-grid/filters/ag-select-column-filter/agSelectColumnFilter.vue';
import actionsCellRenderer from '@/components/ui/ag-grid/cell-renderers/actions-cell-renderer/actionsCellRenderer.vue';
import userCellRenderer from '@/components/ui/ag-grid/cell-renderers/user-cell-renderer/userCellRenderer.vue';
import dialogCellRenderer from '@/components/ui/ag-grid/cell-renderers/dialog-cell-renderer/dialogCellRenderer.vue';
import linkCellRenderer from '@/components/ui/ag-grid/cell-renderers/link-cell-renderer/linkCellRenderer.vue';
import iconCellRenderer from '@/components/ui/ag-grid/cell-renderers/icon-cell-renderer/iconCellRenderer.vue';
import contentCellRenderer from '@/components/ui/ag-grid/cell-renderers/content-cell-renderer/contentCellRenderer.vue';
import activitiesCellRenderer from '@/components/ui/ag-grid/cell-renderers/activities-cell-renderer/activitiesCellRenderer.vue';
import localesCellRenderer from '@/components/ui/ag-grid/cell-renderers/locales-cell-renderer/localesCellRenderer.vue';
import imageLinkCellRenderer from '@/components/ui/ag-grid/cell-renderers/image-link-cell-renderer/imageLinkCellRenderer.vue';
import badgesCellRenderer from '@/components/ui/ag-grid/cell-renderers/badges-cell-renderer/badgesCellRenderer.vue';
import nameAddressCellRenderer from '@/components/ui/ag-grid/cell-renderers/name-address-cell-renderer/nameAddressCellRenderer.vue';
import userInfoCellRenderer from '@/components/ui/ag-grid/cell-renderers/user-info-cell-renderer/user-info-cell-renderer.vue';
import offerStatusCellRenderer from '@/components/ui/ag-grid/cell-renderers/offer-status-cell-renderer/offer-status-cell-renderer.vue';
import { isMobile } from '@/helpers/app.helper';

interface AutoSizeColumnsType {
  enable: boolean,
  skipHeader: boolean | undefined;
}

interface InfinityType {
  enable: boolean,
  apiCallback: GridDatasourceCallback | null;
}

export class AgGridHelper {
  private _gridOptions: GridOptions;
  private _gridApi: GridApi | null = null;
  private _columnApi: ColumnApi | null = null;
  private infinity: InfinityType = {
    enable: false,
    apiCallback: null
  };
  private autoSizeColumns: AutoSizeColumnsType = {
    enable: false,
    skipHeader: undefined,
  };
  // Function to add custom behavior to onGridReady event
  private onReadyCallback: (event: GridReadyEvent) => void = () => {};

  // Use it to pass in ag-grid component
  gridOptions: GridOptions | undefined;

  constructor(gridOptions?: GridOptions) {
    this._gridOptions = {
      ...this.getDefaultGridOptions(),
      ...(gridOptions || {})
    };
  }

  getGridApi() {
    return this._gridApi;
  }

  getColumnApi() {
    return this._columnApi;
  }

  // It's not necessary to use this method if infinity enabled
  setRowData(data: any) {
    this._gridOptions = {
      rowData: data
    };
  }

  refreshInfinity() {
    if (this._gridApi) {
      if (this._gridApi.getInfiniteRowCount() > 0) {
        this._gridApi.refreshInfiniteCache();
      } else {
        this._gridApi.purgeInfiniteCache();
      }
    }
  }

  build() {
    this.gridOptions = this._gridOptions;
    return this;
  }

  setOnReadyCallback(callback: (event: GridReadyEvent) => void) {
    this.onReadyCallback = callback;
    return this;
  }

  enableInfinity(apiCallback: GridDatasourceCallback) {
    this.infinity = {
      enable: true,
      apiCallback
    };
    this._gridOptions = {
      rowModelType: 'infinite',
      cacheBlockSize: 20,
      paginationPageSize: 100,
      cacheOverflowSize: 1,
      maxConcurrentDatasourceRequests: 1,
      infiniteInitialRowCount: 0,
      maxBlocksInCache: 10,
      ...this._gridOptions,
    };

    return this;
  }

  enableAutoSizeColumns(skipHeader?: boolean) {
    this.autoSizeColumns = {
      enable: true,
      skipHeader
    };

    return this;
  }

  enableAutoColumnWindowResize() {
    window.onresize = throttle(() => {
      if (this._gridApi) {
        this._gridApi.sizeColumnsToFit();
      }
    }, 300);
  }

  private getDefaultGridOptions(): GridOptions {
    return {
      enableCellTextSelection: true,
      rowHeight: 40,
      localeText: {
        noRowsToShow: i18n.t('agGrid.noRowsToShow'),
        loadingOoo: i18n.t('agGrid.loadingOoo'),
        filterOoo: i18n.t('agGrid.filterOoo'),
        equals: i18n.t('agGrid.equals'),
        notEqual: i18n.t('agGrid.notEqual'),
        lessThan: i18n.t('agGrid.lessThan'),
        lessThanOrEqual: i18n.t('agGrid.lessThanOrEqual'),
        greaterThan: i18n.t('agGrid.greaterThan'),
        greaterThanOrEqual: i18n.t('agGrid.greaterThanOrEqual'),
        inRange: i18n.t('agGrid.inRange'),
        andCondition: i18n.t('agGrid.andCondition'),
        orCondition: i18n.t('agGrid.orCondition'),
        contains: i18n.t('agGrid.contains'),
        notContains: i18n.t('agGrid.notContains'),
        startsWith: i18n.t('agGrid.startsWith'),
        endsWith: i18n.t('agGrid.endsWith'),
        clearFilter: i18n.t('agGrid.clearFilter'),
        applyFilter: i18n.t('agGrid.applyFilter'),
        resetFilter: i18n.t('agGrid.resetFilter'),
      },
      defaultColDef: {
        resizable: true,
        cellClass: 'ag-grid-cell',
        minWidth: isMobile() ? 280 : 90,
      },
      // suppressHorizontalScroll: true,
      suppressCellSelection: true,
      frameworkComponents: {
        agSelectColumnFilter,
        actionsCellRenderer,
        userCellRenderer,
        dialogCellRenderer,
        linkCellRenderer,
        iconCellRenderer,
        contentCellRenderer,
        activitiesCellRenderer,
        localesCellRenderer,
        imageLinkCellRenderer,
        badgesCellRenderer,
        nameAddressCellRenderer,
        userInfoCellRenderer,
        offerStatusCellRenderer,
      },
      onGridReady: (event: GridReadyEvent) => {
        this._gridApi = event.api;
        this._columnApi = event.columnApi;

        // Auto size columns
        if (this.autoSizeColumns.enable) {
          const allColumnIds: string[] = [];
          event.columnApi.getAllColumns().forEach(column => allColumnIds.push(column.getColId()));
          event.columnApi.autoSizeColumns(allColumnIds, this.autoSizeColumns.skipHeader);
        }

        this.onReadyCallback(event);

        // Infinity scroll
        if (this.infinity.enable && isFunction(this.infinity.apiCallback)) {
          const dataSource = new GridDatasource(event.api, event.columnApi);

          dataSource.registerCallback(this.infinity.apiCallback);
          event.api.setDatasource(dataSource);
        }

        event.api.sizeColumnsToFit();
        this.enableAutoColumnWindowResize();
      }
    }
  }
}
