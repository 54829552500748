import { Component, Vue } from 'vue-property-decorator';
import { ICellRendererParams } from 'ag-grid-community';
import isFunction from 'lodash/isFunction';

type IconCellRendererParams = ICellRendererParams & {
  content: ((params: ICellRendererParams) => any) | any;
  icon?: ((params: ICellRendererParams) => | string | null) | string | null;
  clickCallback?: (params: ICellRendererParams) => any | null;
};

@Component
export default class IconCellRenderer extends Vue {
  params!: IconCellRendererParams;

  get content() {
    if (isFunction(this.params.content)) {
      return this.params.content(this.params);
    }
    return this.params.content || '';
  }

  get icon() {
    if (isFunction(this.params.icon)) {
      return this.params.icon(this.params);
    }
    return this.params.icon;
  }

  onClick() {
    if (isFunction(this.params.clickCallback)) {
      this.params.clickCallback(this.params);
    }
  }
}
