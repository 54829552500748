import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';

@Component({
  components: { ValidationProvider }
})
export default class BRadioInputValidation extends Vue {
  @Prop({ type: String })
  vid?: string;

  @Prop({ type: [Object, String], default: '' })
  rules?: string;

  @Prop()
  value?: string;

  innerValue = '';

  @Watch('innerValue')
  innerValueChange(newVal: string) {
    this.$emit('input', newVal);
  }

  @Watch('value')
  valueChange(newVal: string) {
    this.innerValue = newVal
  }

  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  }
}
