import { ColumnApi, GridApi, IDatasource, IGetRowsParams } from 'ag-grid-community';
import isFunction from 'lodash/isFunction';

export const SORT_ASC = 'asc';
export const SORT_DESC = 'desc';

export interface SortParam {
  colId: string;
  sort: 'asc' | 'desc'
}

export interface ICallbackGeneralParams {
  limit: number;
  offset: number;
  sort: SortParam[];
  filter: any;
}

export interface ICallbackAdditionalParams {
  startRow: number;
  endRow: number;
  successCallback: (rowsThisBlock: any[], lastRow?: number) => void;
  api: GridApi;
  columnApi: ColumnApi;
}

export interface ICallbackResponse<T> {
  result: T[];
  count: number;
}

export type GridDatasourceCallback = (general: ICallbackGeneralParams, additional: ICallbackAdditionalParams) => Promise<ICallbackResponse<any>>;

export class GridDatasource implements IDatasource {
  public rowCount?: number;
  private callback: GridDatasourceCallback | null = null;
  private limit = 0;
  private offset = 0;
  private api: GridApi;
  private columnApi: ColumnApi;

  constructor(api: GridApi, columnApi: ColumnApi) {
    this.api = api;
    this.columnApi = columnApi;
    this.getRows = this.getRows.bind(this);
  }

  registerCallback(callback: GridDatasourceCallback): this {
    if (isFunction(callback)) {
      this.callback = callback;
    }

    return this;
  }

  getRows(params: IGetRowsParams) {
    this.limit = params.endRow - params.startRow;
    this.offset = params.startRow;

    if (this.callback) {
      this.callback(
        {
          limit: this.limit,
          offset: this.offset,
          sort: params.sortModel,
          filter: params.filterModel
        },
        {
          startRow: params.startRow,
          endRow: params.endRow,
          successCallback: params.successCallback,
          api: this.api,
          columnApi: this.columnApi,
        }
      )
        .then((response: ICallbackResponse<any>) => {
          params.successCallback(response.result, response.count);
          if (!response.result || !response.result.length) {
            this.api.showNoRowsOverlay();
          }
        }, (err) => {
          this.api.hideOverlay();
          params.failCallback();
        });
    }
  }
}
