import { Component, Vue } from 'vue-property-decorator';
import { ICellRendererParams } from 'ag-grid-community';
import isFunction from 'lodash/isFunction';

type ImageLinkCellRendererParams = ICellRendererParams & {
  imageUrl: ((params: ICellRendererParams) => any) | any;
  imageClickCallback?: (params: ICellRendererParams) => any | null;
  imageClickable: ((params: ICellRendererParams) => boolean) | boolean;
  clickCallback?: (params: ICellRendererParams) => any | null;
  text: (params: ICellRendererParams) => string | string;
  url?: (params: ICellRendererParams) => string | string;
};

@Component
export default class ImageLinkCellRenderer extends Vue {
  params!: ImageLinkCellRendererParams;

  get text() {
    if (isFunction(this.params.text)) {
      return this.params.text(this.params);
    }
    return this.params.text;
  }

  get url() {
    if (isFunction(this.params.url)) {
      return this.params.url(this.params);
    }
    return this.params.url;
  }

  onClick() {
    if (isFunction(this.params.clickCallback)) {
      this.params.clickCallback(this.params);
    }
  }

  get imageUrl() {
    if (isFunction(this.params.imageUrl)) {
      return this.params.imageUrl(this.params);
    }
    return this.params.imageUrl;
  }

  get imageClickable() {
    if (isFunction(this.params.imageClickable)) {
      return this.params.imageClickable(this.params);
    }
    return this.params.imageClickable || false;
  }

  onImageClick() {
    if (isFunction(this.params.imageClickCallback) && this.imageClickable) {
      this.params.imageClickCallback!(this.params);
    }
  }
}
