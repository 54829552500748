import { Component, Vue } from 'vue-property-decorator';
import { ICellRendererParams } from 'ag-grid-community';
import isFunction from 'lodash/isFunction';

type LinkCellRendererParams = ICellRendererParams & {
  text: ((params: ICellRendererParams) => string) | string;
  url?: ((params: ICellRendererParams) => string) | string;
  clickCallback?: (params: ICellRendererParams) => any | null;
};

@Component
export default class LinkCellRenderer extends Vue {
  params!: LinkCellRendererParams;

  get text() {
    if (isFunction(this.params.text)) {
      return this.params.text(this.params);
    }
    return this.params.text;
  }

  get url() {
    if (isFunction(this.params.url)) {
      return this.params.url(this.params);
    }
    return this.params.url;
  }

  onClick() {
    if (isFunction(this.params.clickCallback)) {
      this.params.clickCallback(this.params);
    }
  }
}
